import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Folder } from '@common/core/models';
import { LoaderService, StoreService } from '@common/core/services';
import { FoldersService } from '@common/core/services/graph-ql/folders.service';
import { EBitfCloseEventStatus, EBitfUiMessageType, ERoleActions, ERoles } from '@common/enums';
import { IBitfCloseEvent, IBitfGraphQlResponse, ICreateUpdateFolderDialogData } from '@common/interfaces';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessagesService } from '@web/core/services';

@Component({
  selector: 'mpa-create-update-folder-dialog',
  templateUrl: './create-update-folder-dialog.component.html',
  styleUrls: ['./create-update-folder-dialog.component.scss'],
})
export class CreateUpdateFolderDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  isInEdit = false;
  eRoleActions = ERoleActions;

  constructor(
    public dialogRef: MatDialogRef<CreateUpdateFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICreateUpdateFolderDialogData,
    private foldersService: FoldersService,
    private loaderService: LoaderService,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.isInEdit = this.dialogData.folder?.id !== undefined;
    this.initForm();
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.dialogData.folder?.id),
      is_image_folder: new UntypedFormControl(this.dialogData.folder?.is_image_folder || false),
      name: new UntypedFormControl(this.dialogData.folder?.name, Validators.required),
      is_visible_to_editor: new UntypedFormControl(
        this.dialogData.folder?.is_visible_to_editor ||
          (!this.dialogData.folder && this.storeService.store.user.role.name === ERoles.EDITOR)
      ),
      is_visible_to_collaborator: new UntypedFormControl(
        this.dialogData.folder?.is_visible_to_collaborator ||
          (!this.dialogData.folder && this.storeService.store.user.role.name === ERoles.COLLABORATOR)
      ),
      is_visible_to_viewer: new UntypedFormControl(this.dialogData.folder?.is_visible_to_viewer || false),
    });
  }

  onSave() {
    this.loaderService.show();
    const folder = new Folder({}, this.form.value);
    if (this.isInEdit) {
      this.foldersService.updateItem({ body: folder }, this.dialogData.folder).subscribe(response => {
        this.toastMessagesService.show({
          title: this.translateService.instant(bitfToTranslate('FOLDER.FOLDER_RENAMED'), {
            folder: this.form.value.name,
          }),
          type: EBitfUiMessageType.SUCCESS,
        });

        this.dialogRef.close({
          status: EBitfCloseEventStatus.OK,
          data: response.content,
        } as IBitfCloseEvent<Folder>);
      });
    } else {
      folder.archives = this.dialogData.items;

      this.foldersService.create({ body: folder }).subscribe((response: IBitfGraphQlResponse<Folder>) => {
        this.toastMessagesService.show({
          title: this.translateService.instant(bitfToTranslate('FOLDER.FOLDER_CREATED'), {
            folder: this.form.value.name,
          }),
          type: EBitfUiMessageType.SUCCESS,
        });

        this.dialogRef.close({
          status: EBitfCloseEventStatus.OK,
          data: response.content,
        } as IBitfCloseEvent<Folder>);
      });
    }
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }
}
