import { SuperStrapiModel } from './super-strapi.model';
import { Role } from './role.model';
import { ERoles } from '@common/enums';

export class User extends SuperStrapiModel {
  username: string;
  email: string;
  confirmed: boolean;
  role: Role;
  first_name: string;
  last_name: string;

  get nickName() {
    if (this.first_name && this.last_name) {
      return `${this.first_name[0]?.toUpperCase()}${this.last_name[0]?.toUpperCase()}`;
    }
    return '';
  }

  get archiveRoleVisibilityObj() {
    if (this.role.name === ERoles.ADMIN) {
      return {};
    }

    return {
      ['is_visible_to_' + this.role.name.toLowerCase()]: true,
    };
  }

  constructor(data: any = {}) {
    super(data);

    if (data?.attributes?.role) {
      this.role = new Role(data.attributes.role.data);
    }
  }

  get serialised() {
    return {};
  }

  get canViewLogs() {
    return true;
  }
}
