import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { BITF_CONFIGS } from '@web/configs';
import { Observable } from 'rxjs';
import { StoreService } from '@common/core/services/store.service';
import { UiRoleManagerService } from '@common/core/services/ui-role-manager.service';
import { environment } from '@env/environment';
import { EAppSections } from '@web/enums';

@Injectable({
  providedIn: 'root',
})
export class AppSectionsGuard {
  constructor(
    private router: Router,
    private storeService: StoreService,
    private uiRoleManagerService: UiRoleManagerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url.includes('cartelle')) {
      return this.checkDynamicModule(EAppSections.FOLDER);
    }
    if (state.url.includes('prestiti')) {
      return this.checkDynamicModule(EAppSections.LOAN);
    }
    if (state.url.includes('scarichi')) {
      return this.checkDynamicModule(EAppSections.SALE);
    }
    if (state.url.includes('upload')) {
      return this.checkDynamicModule(EAppSections.UPLOAD);
    }
    if (state.url.includes('qr-scanner')) {
      return this.checkDynamicModule(EAppSections.QR_SCANNER);
    }

    this.router.navigate([BITF_CONFIGS.urls.homePageUrl]);
    return false;
  }

  private checkDynamicModule(module: EAppSections): boolean {
    if (environment.appSections.includes(module)) {
      return true;
    }
    this.router.navigate([BITF_CONFIGS.urls.homePageUrl]);
    return false;
  }
}
