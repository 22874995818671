import { USER_RELATION_ENTITY } from '../api/users.gql';

export const FOLDER = `
  fragment Folder on Folder {
    name
    is_image_folder
    is_visible_to_editor
    is_visible_to_collaborator
    is_visible_to_viewer
    createdAt
  }
`;

export const FOLDER_ENTITY = `
  ${FOLDER}
  fragment FolderEntity on FolderEntity {
    id
    attributes {
      ...Folder
    }
  }
`;

export const FOLDER_ENTITY_WITH_ARCHIVES = `
  ${FOLDER}
  ${USER_RELATION_ENTITY}
  fragment FolderEntityWithArchives on FolderEntity {
    id
    attributes {
      ...Folder
      ...UserRelationEntity
      archives(pagination: { limit: 5000 }) {
        data {
          id
          attributes {
            is_visible_to_editor
            is_visible_to_collaborator
            is_visible_to_viewer
          }
        }
      }
    }
  }
`;
