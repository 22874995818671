<mat-card class="item-card"
  [ngClass]="{'with-value':uiRoleManagerService.canI(eRoleActions.VIEW_VALUE), 'selected': isSelected}">

  <mat-card-header class="pt-2 px-2">
    <mat-card-title [title]="item.description"
      class="item-title">{{item.description}}
    </mat-card-title>

    <mat-card-subtitle class="item-icons">
      <div class="d-flex align-items-center">
        <span class="mr-1">{{item.inventory}}</span>
        <div class="flex-grow-1"></div>

        <mat-icon class="mr-1 cursor-pointer"
          *ngIf="item.is_sold && item.sale?.date"
          [routerLink]="['/scarichi/lista',item?.sale?.id]"
          color="primary"
          matTooltipClass="multi-line-tooltip"
          [matTooltip]="getSaleTooltipText()">event</mat-icon>
        <mat-icon class="mr-1 cursor-pointer"
          *ngIf="item.isInLoanOut"
          [routerLink]="['/prestiti',item?.loanOut?.loan?.id]"
          color="primary"
          [matTooltip]="'COMMON.LABEL.BORROWED' | translate">work</mat-icon>
        <mat-icon color="primary"
          *ngIf="item.suit?.id"
          [matTooltip]="'COMMON.LABEL.SUIT' | translate">category</mat-icon>
      </div>

      <div class="d-flex justify-content-between border-top pt-1 mt-1 value"
        *bitfUiRoleManager="{action : eRoleActions.VIEW_VALUE}">
        <div>
          <ng-container *ngIf="showQuantity">
            N&deg; <strong *ngIf="item.quantity !== undefined">{{item.quantity}}</strong>
            <strong *ngIf="item.quantity === undefined">n.d</strong>
          </ng-container>
        </div>
        <div>
          <strong *ngIf="item.value !== undefined">{{item.value | bitfNumber: '.0'}}</strong>
          <strong *ngIf="item.value === undefined">n.d.</strong>
          &euro;
        </div>
      </div>
    </mat-card-subtitle>

    <mat-icon *ngIf="isSelected"
      class="selected-icon position-absolute">
      check_circle
    </mat-icon>
  </mat-card-header>

  <mat-card-content class="d-flex position-relative"
    (click)="onSelect()"
    matTooltipPosition="below">
    <mpa-item-images class="item-image-container"
      [item]="item"
      [showImagesCounter]="false"></mpa-item-images>
  </mat-card-content>

  <mat-card-actions class="d-flex justify-content-between align-items-center button-density-2">
    <button (click)="onItemDetail()"
      mat-icon-button>
      <mat-icon>visibility</mat-icon>
    </button>

    <button (click)="onItemDetail(true)"
      mat-icon-button>
      <mat-icon>zoom_out_map</mat-icon>
    </button>

    <button mat-icon-button
      matTooltipClass="multi-line-tooltip"
      [matTooltip]="getTooltipText()"
      matTooltipPosition="above">
      <mat-icon color="primary">info</mat-icon>
    </button>

    <button mat-icon-button
      *ngIf="!isReadOnly"
      [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item
        *bitfUiRoleManager="{action: eRoleActions.DELETE_ITEM}"
        (click)="onDelete('deleteItem')"
        class="text-danger">
        <span>{{'COMMON.LABEL.DELETE' | translate}}</span>
      </button>

      <button mat-menu-item
        *ngIf="folder && uiRoleManagerService.canI(eRoleActions.MANAGE_FOLDERS)"
        (click)="onDelete('deleteFromFolder')"
        class="text-danger">
        <span>{{'FOLDER.REMOVE_FROM_FOLDER' | translate}}</span>
      </button>
    </mat-menu>

  </mat-card-actions>
</mat-card>