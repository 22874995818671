export enum ERoleMode {
  READ_ONLY = 'READ_ONLY',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
  INVISIBLE = 'INVISIBLE',
}

export enum ERoles {
  ADMIN = 'admin',
  VIEWER = 'viewer',
  COLLABORATOR = 'collaborator',
  EDITOR = 'editor',
}

export enum ERoleActions {
  VIEW_BULK_ACTIONS = 'VIEW_BULK_ACTIONS',
  EDIT_ARCHIVE_ITEM = 'EDIT_ARCHIVE_ITEM',
  EDIT_LOAN = 'EDIT_LOAN',
  EDIT_SALE = 'EDIT_SALE',
  EDIT_ITEM = 'EDIT_ITEM',
  DELETE_ITEM = 'DELETE_ITEM',
  CREATE_ITEM = 'CREATE_ITEM',
  VIEW_VALUE = 'VIEW_VALUE',
  EDIT_VALUE = 'EDIT_VALUE',
  PRINT_VALUE = 'PRINT_VALUE',
  VIEW_FOLDER = 'VIEW_FOLDER',
  MANAGE_VIEWER_FOLDERS = 'MANAGE_VIEWER_FOLDERS',
  MANAGE_COLLABORATOR_FOLDERS = 'MANAGE_COLLABORATOR_FOLDERS',
  MANAGE_EDITOR_FOLDERS = 'MANAGE_EDITOR_FOLDERS',
  MANAGE_VIEWER_ARCHIVES = 'MANAGE_VIEWER_ARCHIVES',
  MANAGE_COLLABORATOR_ARCHIVES = 'MANAGE_COLLABORATOR_ARCHIVES',
  MANAGE_EDITOR_ARCHIVES = 'MANAGE_EDITOR_ARCHIVES',
  MANAGE_FOLDERS = 'MANAGE_FOLDERS',
  UPDATE_FOLDER = 'UPDATE_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  MANAGE_LOANS = 'MANAGE_LOANS',
  DELETE_LOAN = 'DELETE_LOAN',
  BULK_EDIT_LOAN_ITEMS = 'BULK_EDIT_LOAN_ITEMS',
  MANAGE_SALES = 'MANAGE_SALES',
  DELETE_SALE = 'DELETE_SALE',
  BULK_EDIT_SALE_ITEMS = 'BULK_EDIT_SALE_ITEMS',
}
