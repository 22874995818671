import {
  ARCHIVE_BASE_PROPERTIES,
  ARCHIVE_IMAGES_ENTITY,
  ARCHIVE_LOAN,
  FOLDER_ENTITY,
  IMAGE_ENTITY,
  LOAN_ENTITY,
  PAGINATION_ENTITY,
  SUIT_ENTITY,
  SUIT_ENTITY_WITH_RELATIONS,
} from '@common/core/services';
import { TYPOLOGIES } from './fashion-typologies.gql';

export const ARCHIVE_SHARED_PROPS_AND_RELATIONS = `
  ${SUIT_ENTITY_WITH_RELATIONS}
  ${ARCHIVE_IMAGES_ENTITY}
  ${TYPOLOGIES}
  fragment ArchiveSharedPropsAndRelations on Archive {
    conservation_status_details
    fantasy_details
    year
    size
    object_description
    external_links
    is_hanging
    createdAt
    updatedAt
    ...Typologies
    details {
      data {
          id
          attributes {
            name
          }
      }
    }
    conservation_status {
      data {
        id
        attributes {
          name
        }
      }
    }
    apartment {
      data {
        id
        attributes {
          name
        }
      }
    }
    place {
      data {
        id
        attributes {
          name
        }
      }
    }
    shelf {
      data {
        id
        attributes {
          name
        }
      }
    }
    sector {
      data {
        id
        attributes {
          name
        }
      }
    }
    box {
      data {
        id
        attributes {
          name
        }
      }
    }
    mainColor {
      data {
        id
        attributes {
          name
        }
      }
    }
    colors {
      data {
        id
        attributes {
          name
        }
      }
    }
    mainMaterial {
      data {
        id
        attributes {
          name
        }
      }
    }
    materials {
      data {
        id
        attributes {
          name
        }
      }
    }
    techniques {
      data {
        id
        attributes {
          name
        }
      }
    }
    season {
      data {
        id
        attributes {
          name
        }
      }
    }
    fantasy {
      data {
        id
        attributes {
          name
        }
      }
    }
    brand {
      data {
        id
        attributes {
          name
        }
      }
    }
    images {
      data {
        ...ImageEntity
      }
    }
    suit {
      data {
        ...SuitEntityWithRelations
      }
    }
    links_from {
      data {
        ...ArchiveImagesEntity
      }
    }
    links_to {
      data {
        ...ArchiveImagesEntity
      }
    }
    lookbooks_from {
      data {
        ...ArchiveImagesEntity
      }
    }
    lookbooks_to {
      data {
        ...ArchiveImagesEntity
      }
    }
  }
`;

export const ARCHIVE_GALLERY = `
  ${PAGINATION_ENTITY}
  ${ARCHIVE_BASE_PROPERTIES}
  ${IMAGE_ENTITY}
  ${ARCHIVE_IMAGES_ENTITY}
  ${SUIT_ENTITY}
  query Query($pagination: PaginationArg, $sort: [String], $filters: ArchiveFiltersInput) {
    archives(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          ...ArchiveBaseProperty
          mainMaterial {
            data {
              id
              attributes {
                name
              }
            }
          }
          techniques {
            data {
              id
              attributes {
                name
              }
            }
          }
          images {
            data {
              ...ImageEntity
            }
          }
          suit {
            data {
              ...SuitEntity
            }
          }
          links_from {
            data {
              ...ArchiveImagesEntity
            }
          }
          links_to {
            data {
              ...ArchiveImagesEntity
            }
          }
          lookbooks_from {
            data {
              ...ArchiveImagesEntity
            }
          }
          lookbooks_to {
            data {
              ...ArchiveImagesEntity
            }
          }
          archive_loans {
            data {
              id
              attributes {
                date_in
                date_out
                loan {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          ...PaginationEntity
        }
      }
    }
  }
`;

export const ARCHIVE_PRINT_DATA = `
  ${PAGINATION_ENTITY}
  ${ARCHIVE_BASE_PROPERTIES}
  ${ARCHIVE_SHARED_PROPS_AND_RELATIONS}
  query Query($pagination: PaginationArg, $sort: [String], $filters: ArchiveFiltersInput) {
    archives(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          ...ArchiveBaseProperty
          ...ArchiveSharedPropsAndRelations
        }
      }
      meta {
        pagination {
          ...PaginationEntity
        }
      }
    }
  }
`;

export const LOAN_PRINT_DATA = `
  ${ARCHIVE_BASE_PROPERTIES}
  ${ARCHIVE_SHARED_PROPS_AND_RELATIONS}
  ${IMAGE_ENTITY}
  ${TYPOLOGIES}
  query Query($id: ID) {
    loan(id: $id) {
      data {
        attributes {
          note
          reference
          date_out
          archive_loans(pagination: { limit: 1000 }) {
            data {
              attributes {
                archive {
                  data {
                    id
                    attributes {
                      ...ArchiveBaseProperty
                      ...ArchiveSharedPropsAndRelations
                      ...Typologies
                      images {
                        data {
                          ...ImageEntity
                        }
                      }
                      suit {
                        data {
                          id
                        }
                      }
                    }
                  }
                }
                note_in
                note_out
                date_in
                date_out
              }
            }
          }
        }
      }
    }
  }
`;

export const SALE_PRINT_DATA = `
  ${ARCHIVE_BASE_PROPERTIES}
  ${ARCHIVE_SHARED_PROPS_AND_RELATIONS}
  ${IMAGE_ENTITY}
  ${TYPOLOGIES}
  query Query($id: ID) {
    sale(id: $id) {
      data {
        attributes {
          note
          reference
          date
          archive_sales(pagination: { limit: 1000 }) {
            data {
              attributes {
                archive {
                  data {
                    id
                    attributes {
                      ...ArchiveBaseProperty
                      ...ArchiveSharedPropsAndRelations
                      ...Typologies
                      images {
                        data {
                          ...ImageEntity
                        }
                      }
                    }
                  }
                }
                note_out
              }
            }
          }
        }
      }
    }
  }
`;

export const ARCHIVE_WITH_ALL_RELATIONS_ENTITY = `
  ${ARCHIVE_BASE_PROPERTIES}
  ${ARCHIVE_SHARED_PROPS_AND_RELATIONS}
  ${FOLDER_ENTITY}
  ${ARCHIVE_LOAN}
  ${LOAN_ENTITY}
  fragment ArchiveWithAllRelationsEntity on ArchiveEntity {
    id
    attributes {
      ...ArchiveBaseProperty
      ...ArchiveSharedPropsAndRelations
      folders {
        data {
          ...FolderEntity
        }
      }
      archive_loans {
        data {
          id
          attributes {
            ...ArchiveLoan
            loan {
              data {
                ...LoanEntity
              }
            }
          }
        }
      }
    }
  }
`;
