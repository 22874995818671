import { IMAGE_ENTITY } from './images.gql';

export const ARCHIVE_LOAN = `
  fragment ArchiveLoan on ArchiveLoan {
    date_in
    date_out
    note_in
    note_out
  }
`;

export const ARCHIVE_LOAN_ENTITY = `
  ${ARCHIVE_LOAN}
  ${IMAGE_ENTITY}
  fragment ArchiveLoanEntity on ArchiveLoanEntity {
    id
    attributes {
      ...ArchiveLoan
      archive {
        data {
          id
          attributes {
            inventory
            description
            images {
              data {
                ...ImageEntity
              }
            }
            archive_loans {
              data {
                id
                attributes {
                  ...ArchiveLoan
                  loan {
                    data {
                      id
                      attributes {
                        name
                        date_in
                        date_out
                        note
                        reference
                      }
                    }
                  }
                }
              }
            }

            is_visible_to_editor
            is_visible_to_collaborator
            is_visible_to_viewer
          }
        }
      }
      loan {
        data {
          id
          attributes {
            name
            date_in
            date_out
            note
            reference
          }
        }
      }
    }
  }
`;
