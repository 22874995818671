import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BitfGraphQlService } from '@common/libs/bitforce/core/services/graph-ql/bitf-graph-ql.service';

import { ApiCallStateService, LoaderService, StoreService } from '@common/core/services';
import {
  IBitfApiPagination,
  IBitfApiResponse,
  IBitfGraphQlRequest,
  IBitfGraphQlResponse,
} from '@common/interfaces';
import { EApiCallStateNames, EApiRequestPartKeys, ERoles } from '@web/enums';
import { BitfApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-api-request-part';

@Component({
  selector: 'mpa-super-list',
  template: '',
})
export abstract class SuperListComponent<T> implements OnInit, OnDestroy {
  abstract apiCallStateName: EApiCallStateNames;
  abstract service: BitfGraphQlService;
  abstract MAX_PRINTABLE: number;

  public items: T[];
  public pagination: IBitfApiPagination;
  public eApiRequestPartKeys = EApiRequestPartKeys;
  protected apiCallStateService: ApiCallStateService;
  protected loaderService: LoaderService;
  protected storeService: StoreService;
  protected filterRequestPart: BitfApiRequestPart;

  selectedItems = new Map<number, T>();
  unselectedItems = new Map<number, T>();
  allItemsSelected = false;

  protected subscriptions = new Subscription();

  constructor(public injector: Injector) {
    this.apiCallStateService = this.injector.get(ApiCallStateService);
    this.loaderService = this.injector.get(LoaderService);
    this.storeService = this.injector.get(StoreService);
  }

  protected abstract getApiCall(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<T[]>>;
  protected abstract doPrint(request: IBitfGraphQlRequest): void;

  ngOnInit(): void {
    this.loadData();

    this.subscriptions.add(
      this.apiCallStateService
        .getStateStore$(this.apiCallStateName)
        .pipe(
          tap(() => {
            this.loadData();
          })
        )
        .subscribe()
    );
  }

  onAllItemsSelected(event): void {
    if (event && this.selectedItems.size) {
      setTimeout(() => {
        this.allItemsSelected = false;
      }, 0);
    } else {
      this.allItemsSelected = event;
    }
    this.selectedItems.clear();
    this.unselectedItems.clear();
  }

  onSelectItem(row: T) {
    if (this.allItemsSelected) {
      if (this.unselectedItems.has(row['id'])) {
        this.unselectedItems.delete(row['id']);
      } else {
        this.unselectedItems.set(row['id'], row);
      }
    } else {
      if (this.selectedItems.has(row['id'])) {
        this.selectedItems.delete(row['id']);
      } else {
        this.selectedItems.set(row['id'], row);
      }
    }

    if (
      this.unselectedItems.size === this.pagination.totalItems ||
      this.selectedItems.size === this.pagination.totalItems
    ) {
      this.allItemsSelected = this.selectedItems.size === this.pagination.totalItems;
      this.selectedItems.clear();
      this.unselectedItems.clear();
    }
  }

  isItemSelected(item: T) {
    return (
      (this.allItemsSelected && !this.unselectedItems.has(item['id'])) || this.selectedItems.has(item['id'])
    );
  }

  onPrint() {
    const requestParams: IBitfGraphQlRequest = this.apiCallStateService.getApiRequest(this.apiCallStateName);

    const request: IBitfGraphQlRequest = {
      ...requestParams,
      size: this.MAX_PRINTABLE,
    };
    if (!this.allItemsSelected) {
      request.filter = [...request.filter, { and: { id: { in: [...this.selectedItems.keys()] } } }];
    } else {
      request.filter = [...request.filter, { and: { id: { notIn: [...this.unselectedItems.keys()] } } }];
    }
    let totalItems: number;
    if (this.allItemsSelected && !this.unselectedItems.size) {
      totalItems = this.pagination.totalItems;
    } else if (this.selectedItems.size) {
      totalItems = this.selectedItems.size;
    } else {
      totalItems = this.pagination.totalItems - this.unselectedItems.size;
    }

    request.page = 1;
    this.doPrint(request);
  }

  private loadData() {
    this.loaderService.show();
    const requestParams = this.apiCallStateService.getApiRequest(this.apiCallStateName);
    this.getApiCall(requestParams)
      .pipe(
        tap((response: IBitfApiResponse<T[]>) => {
          this.pagination = response.pagination;
          this.items = this.filterArchivesByVisibility(response.content);
        })
      )
      .subscribe();
  }

  private filterArchivesByVisibility(items: T[]) {
    items.forEach(item => {
      let attr = null;
      let nestedAttr = null;
      if (this.storeService.store.user.role.name !== ERoles.ADMIN) {
        nestedAttr = 'is_visible_to_' + this.storeService.store.user.role.name.toLowerCase();
      }

      if (item['archives']) attr = 'archives';
      if (item['archive_loans']) attr = 'archive_loans';
      if (item['archive_sales']) attr = 'archive_sales';

      if (nestedAttr) {
        item[attr] = item[attr].filter(a => (a['archive'] ? a['archive'][nestedAttr] : a[nestedAttr]));
      }
    });

    return items;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
