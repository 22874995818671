import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Folder } from '@common/core/models';
import { FoldersService } from '@common/core/services/graph-ql/folders.service';
import { EBitfApiSortDirection, EBitfCloseEventStatus, EBitfUiMessageType, ERoles } from '@common/enums';
import { IBitfCloseEvent, ICreateUpdateFolderDialogData } from '@common/interfaces';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService, StoreService, ToastMessagesService } from '@web/core/services';
import { switchMap, finalize } from 'rxjs/operators';
import { IBitfGraphQlResponse } from '@common/interfaces';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'mpa-bulk-copy-into-folder-dialog',
  templateUrl: './bulk-copy-into-folder-dialog.component.html',
  styleUrls: ['./bulk-copy-into-folder-dialog.component.scss'],
})
export class BulkCopyIntoFolderDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public folders: Folder[] = [];
  public filteredFolders: Folder[] = [];
  public selectedFolder: Folder;

  constructor(
    public dialogRef: MatDialogRef<BulkCopyIntoFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICreateUpdateFolderDialogData,
    private foldersService: FoldersService,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initFoldersList();
  }

  onSelectFolder(event: MatAutocompleteSelectedEvent) {
    this.selectedFolder = event.option.value;
  }

  onSave() {
    const folder = this.form.value.folder;
    // NOTE: we've to fetch the folder with his own archives
    this.loaderService.show();

    this.foldersService
      .getItemById({ id: folder.id, disableHideLoader: true })
      .pipe(
        switchMap((response: IBitfGraphQlResponse<Folder>) => {
          const folderToUpdate = response.content;
          folderToUpdate.archives = [
            ...new Map(
              [...folderToUpdate.archives, ...this.dialogData.items].map(item => [item.id, item])
            ).values(),
          ];
          return this.foldersService.updateItem({ body: folderToUpdate, disableHideLoader: true });
        }),
        finalize(() => {
          this.loaderService.hide();

          this.dialogRef.close({
            status: EBitfCloseEventStatus.OK,
          } as IBitfCloseEvent<void>);
        })
      )
      .subscribe(() => {
        this.toastMessagesService.show({
          title: this.translateService.instant(bitfToTranslate('FOLDER.ITEMS_COPIED'), {
            folder: this.form.value.folder.name,
          }),
          type: EBitfUiMessageType.SUCCESS,
        });
      });
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }

  displayFn(folder): string {
    return folder?.name ? `ID:${folder.id} - ${folder.name}` : '';
  }

  onClearFolderInput() {
    this.form.patchValue({ folder: '' }, { emitEvent: false });
    this.filteredFolders = this.folders.filter(option => option.name.toLowerCase().includes(''));
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      folder: new UntypedFormControl(undefined, Validators.required),
    });

    this.form.valueChanges.subscribe(res => {
      if (typeof res.folder === 'string') {
        this.filteredFolders = this.folders.filter(
          option =>
            option.name.toLowerCase().includes(res.folder.toLowerCase()) ||
            (option.id && option.id.toString().includes(res.folder.toLowerCase()))
        );
        // NOTE: If the string match the folder name we've to select it
        if (this.filteredFolders.length === 1) {
          this.selectedFolder = this.filteredFolders[0];
          this.form.patchValue({ folder: this.selectedFolder }, { emitEvent: false });
        } else {
          this.selectedFolder = undefined;
        }
      } else if (res.folder instanceof Folder) {
        // If the folder is selected clicking in the list
        this.selectedFolder = this.filteredFolders[0];
      }
    });
  }

  private initFoldersList() {
    // NOTE: For perf reason we don't read the content of all folders
    // but just the folder name
    const visibilityFilter: any = {};
    if (this.storeService.store.user.role.name === ERoles.EDITOR) {
      visibilityFilter.is_visible_to_editor = {
        eq: true,
      };
    }
    if (this.storeService.store.user.role.name === ERoles.COLLABORATOR) {
      visibilityFilter.is_visible_to_collaborator = {
        eq: true,
      };
    }
    if (this.storeService.store.user.role.name === ERoles.VIEWER) {
      visibilityFilter.is_visible_to_viewer = {
        eq: true,
      };
    }

    this.foldersService
      .get({
        page: 0,
        size: 100000,
        filter: [
          {
            is_image_folder: { eq: false },
            ...visibilityFilter,
          },
        ],
        sorting: [
          {
            property: 'createdAt',
            direction: EBitfApiSortDirection.DESC,
          },
        ],
      })
      .subscribe(res => {
        this.folders = res.content;
        // remove the current folder from the list
        if (this.dialogData.folder) {
          this.folders = this.folders.filter(folder => folder.id !== this.dialogData.folder.id);
        }

        this.filteredFolders = [...this.folders];
      });
  }
}
