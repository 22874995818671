import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Loan } from '@common/core/models';

@Component({
  selector: 'mpa-create-update-loan',
  templateUrl: './create-update-loan.component.html',
  styleUrls: ['./create-update-loan.component.scss'],
})
export class CreateUpdateLoanComponent implements OnInit {
  @Input() loan: Loan;
  @Input() isReadOnly: boolean;
  public form: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }, Validators.required),
      reference: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }),
      date_out: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }, [
        Validators.required,
      ]),
      note: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }),
    });

    if (this.loan) {
      this.form.patchValue(this.loan);
    } else {
      this.form.patchValue({
        date_out: new Date(),
      });
    }
  }
}
