import { EArchiveType, EAppSections, EItemTabs, EShortAppName } from '@web/enums';

const baseApiUrl = 'http://localhost:3002/';
const apiUrl = `${baseApiUrl}`;
const graphQlUrl = 'https://mpa-be.sintrasviluppo.it/graphql';

export const environment = {
  production: true,
  name: 'production',
  appName: 'Miuccia Prada Archivio',
  shortAppName: EShortAppName.MPA,
  appUrl: 'https://mpa.sintrasviluppo.it/',
  storageVersion: '1.0.0',
  archiveType: EArchiveType.FASHION,
  appSections: [
    // EAppSections.SALE,
    EAppSections.FOLDER,
    EAppSections.LOAN,
    EAppSections.UPLOAD,
    EAppSections.QR_SCANNER,
  ],
  itemTabs: [EItemTabs.IMAGES, EItemTabs.SUIT, EItemTabs.LINKS, EItemTabs.LOOKBOOK, EItemTabs.NEIGHBOURS],
  apiUrl,
  graphQlUrl,
  authInterceptorUrls: [apiUrl, graphQlUrl],
  mockApiUrl: 'https://localhost:3002/',
  logUrl: '',
  sockets: { url: '' },
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 6000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  graphQlConfig: {
    enabled: true,
    requestTimeout: 1800000,
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
    logDecoratedExceptions: true,
    logExceptions: true,
    saveLogMessagesInterval: 30000,
    logUrl: 'https://logs.logdna.com/logs/ingest?apikey=bf8cf553dba4a1e882fa09491aaed2c7',
    sendLogs: true,
    sendRequestBody: false,
    sendQueryParams: false,
  },
  tagManager: {
    enabled: false,
    apiKey: '',
  },
  registerServiceWorker: false,
  httpPassword: 'mpatest',
};
